<template>
	<v-container fluid>
		<please-select-utility>
			<v-row>
				<v-col cols="12" class="d-flex">
					<session-select
						:utility="utility"
						v-model="selectedSession1"
						:session.sync="sessionRow1"
						:readonly="loading"
						dense outlined hide-details single-line label="Сесия 1"
						style="max-width: 400px"
						class="mr-2"
					/>
					<session-select
						:utility="utility"
						v-model="selectedSession2"
						:session.sync="sessionRow2"
						:readonly="loading"
						dense outlined hide-details single-line label="Сесия 2"
						style="max-width: 400px"
						class="mr-2"
					/>
				</v-col>
			</v-row>
			<v-row v-if="selectedSessions">
				<v-col>
					<v-btn small color="primary" v-if="!loadingAll" @click="start" width="120">Пусни всички</v-btn>
					<v-btn small color="error" v-else @click="stop" width="120">
						<v-progress-circular indeterminate size="20" width="2" class="mr-2" />
						Стоп
					</v-btn>
				</v-col>
			</v-row>
			<v-row v-if="selectedSessions">
				<v-col>
					<ep-list v-model="expansion">

						<brl
							:utility="utility"
							:session1="selectedSession1"
							:session2="selectedSession2"
							:imodel="imodel"
							ref="brl"
							@beforeload="stop"
						/>

					</ep-list>
				</v-col>
			</v-row>
		</please-select-utility>
	</v-container>
</template>

<script>
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility";
import SessionSelect from "@/views/sessions/SessionSelect";
import Model from "./Model";
import expansionPanelShort from "@/ittijs/ExpansionPanelShort";
import Brl from "@/views/report/sessionCompare/components/brl";

const model = new Model;

const queueRefs = [
	'brl',
];

export default {
	components: {
		Brl,
		SessionSelect,
		PleaseSelectUtility,
	},
	mixins: [
		expansionPanelShort,
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility:'selectedUtility',
		}),
	],
	props: [
		'utility',
	],
	data(){
		return {
			selectedSession1: null,
			selectedSession2: null,
			loading: false,
			loadingAll: false,
			sessionRow1: {},
			sessionRow2: {},
			expansion: null,
			imodel: model,
			currentRunning: null,
		}
	},
	computed: {
		selectedSessions(){
			return this.selectedSession1 && this.selectedSession2 && (this.selectedSession1 !== this.selectedSession2);
		},
	},
	watch: {
		utility(){
			this.selectedSession1 = null;
			this.selectedSession2 = null;
			this.expansion = null;
		},
		session(){
			this.expansion = null;
		},
	},
	methods: {
		async start(){
			if (this.loadingAll) return;
			this.loadingAll = true;
			try {
				for (const ref of queueRefs) {
					this.currentRunning = this.$refs[ref];
					this.$refs[ref]
					&& this.$refs[ref].start
					&& await this.$refs[ref].start();
					this.currentRunning = null;
				}
			}
			finally {
				this.loadingAll = false;
			}
		},
		stop(){
			if (!this.loadingAll) return;
			this.loadingAll = false;
			if (this.currentRunning) {
				this.currentRunning.stop();
			}
		},
	},
}
</script>
