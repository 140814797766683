<template>
	<panel-base :checked="data!==null" :ok="data && data.length===0" :loading="loading" @start="start" @stop="stop">
		<template #heading>
			Брой лица
			<span v-if="data">
				({{ data.length }})
			</span>
		</template>
		<template #content v-if="data">
			<imot-list
				:utility="utility"
				:session="session1"
				:items="data"
				:headers="imotHeaders"
			/>
		</template>
	</panel-base>
</template>
<script>
import expansionPanelShort from "@/ittijs/ExpansionPanelShort";
import PanelBase from "@/views/report/checks/panelBase";
import ImotList from "@/views/report/checks/imot-list";
import checkMixin from "@/views/report/checks/checkMixin";

export default {
	components: {ImotList, PanelBase},
	mixins: [
		expansionPanelShort,
		checkMixin(
			(self, getController) => self.imodel.brl(self.session1, self.session2, getController)
		),
	],
	props: [
		'utility',
		'session1',
		'session2',
		'imodel',
	],
	data() {
		return {
			imotHeaders: [
				{text: "Брой лица 1", value: "brl1", align: "end"},
				{text: "Брой лица 2", value: "brl2", align: "end"},
			],
		}
	},
	watch: {
		utility(){ this.reset() },
		session1(){ this.reset() },
		session2(){ this.reset() },
	},
}
</script>